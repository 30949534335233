import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { environment } from "environments/environment";
import { NotificationService } from "app/_shared/services/notification.service";
import { AppStore } from "app/_shared/stores/app.store";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private zone: NgZone,
    private appStore: AppStore,
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      this.zone.run(() => {
        if (error?.status === 401) {
          this.appStore.setState({ user: null, permissions: [] });
          this.router.navigate(["login"]);
        } else {
          this.notificationService.error(error?.error?.message ?? "An error has occurred.");
        }
      });
    }
    if (!environment.production) {
      console.error(error);
    }
  }
}
