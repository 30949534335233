import { Component, OnInit } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-blank",
  templateUrl: "./blank.component.html",
  styleUrls: [],
})
export class BlankComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
