import { AfterContentInit, ChangeDetectorRef, Directive, ElementRef, Input } from "@angular/core";

@Directive({
  standalone: false,
  selector: "[autofocus]",
})
export class AutofocusDirective implements AfterContentInit {
  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  private _autofocus;

  @Input() set autofocus(condition: boolean | string) {
    this._autofocus = condition !== false;
  }

  ngAfterContentInit() {
    if (this._autofocus || typeof this._autofocus === "undefined") {
      this.el.nativeElement.focus();
      this.cdr.detectChanges();
    }
  }
}
