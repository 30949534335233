import { CsvExportParams, ExcelExportParams, GridApi, RowNode } from "ag-grid-enterprise";
import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import {
  getEntity,
  selectAllEntities,
  selectEntity,
  setEntities,
  UIEntitiesRef,
  upsertEntities,
  withEntities,
  withUIEntities,
  getAllEntities,
} from "@ngneat/elf-entities";
import { excludeKeys, localStorageStrategy, persistState } from "@ngneat/elf-persist-state";
import { TableName, datatables } from "app/_shared/utils/ag-grid.utils";
import { TableState, filterTableState } from "app/_shared/types/models/table";
import { formatDate } from "app/_shared/utils/app.utils";
import { catchError, debounceTime, delay, filter, first, map, Observable, Subscription, switchMap } from "rxjs";
import { pickBy } from "lodash";

interface TableStateUI {
  id: TableName;
  total: number;
}

const store = createStore({ name: "tables" }, withEntities<TableState>(), withUIEntities<TableStateUI>());

export const persist = persistState(store, {
  key: "tables",
  storage: localStorageStrategy,
  source: () =>
    store
      .pipe(
        map((data) => ({
          ...data,
          entities: pickBy(
            data.entities,
            (entity) => datatables.includes(entity.id as any) || entity.id.startsWith("inventory-"),
          ),
        })),
      )
      .pipe(excludeKeys(["UIIds", "UIEntities"]), debounceTime(1000)),
});

@Injectable({ providedIn: "root" })
export class TableStore {
  private subscriptions: Subscription[] = [];

  data = () => store.query(getAllEntities()).filter(filterTableState);
  gridState = (id: string) => store.query(getEntity(id))?.gridState;
  externalFilter = <T>(id: string) => store.query(getEntity(id)).externalFilter as T;

  table$ = (id: TableName) => store.pipe(selectEntity(id));
  tables$ = store.pipe(selectAllEntities());
  quickFilter$ = (id: TableName) => store.pipe(selectEntity(id, { pluck: "quickFilter" }));
  externalFilter$ = (id: TableName) => store.pipe(selectEntity(id, { pluck: "externalFilter" }));
  rangeFilter$ = (id: TableName) => store.pipe(selectEntity(id, { pluck: "rangeFilter" }));

  reset$ = (id: TableName) => store.pipe(selectEntity(id, { pluck: "reset" })).pipe(filter(Boolean));
  export$ = (id: TableName) => store.pipe(selectEntity(id, { pluck: "export" })).pipe(filter(Boolean));

  total$ = (id: TableName) =>
    store.pipe(selectEntity(id, { pluck: "total", ref: UIEntitiesRef })).pipe(debounceTime(200));

  setTable(id: TableName, state: Partial<TableState>) {
    store.update(upsertEntities({ id, ...state }));
  }

  setTableUi(id: TableName, state: Partial<TableStateUI>) {
    store.update(upsertEntities({ id, ...state }, { ref: UIEntitiesRef }));
  }

  setEntities(state: TableState[]) {
    store.update(setEntities(state));
  }

  //init datatables
  initTable<T>(id: TableName, gridApi: GridApi, api: Observable<T[]>, isProgressive: boolean = false) {
    const subscription = this.table$(id)
      .pipe(
        first(),
        switchMap(() => {
          return api.pipe(
            delay(300),
            catchError((err) => {
              gridApi.setGridOption("rowData", []);
              throw err;
            }),
          );
        }),
      )
      .subscribe((data) => {
        if (isProgressive) {
          gridApi.applyTransaction({ add: data });
        } else {
          gridApi.setGridOption("rowData", data);
        }
      });
    this.subscriptions.push(subscription);
  }

  initCallbacks(id: TableName, gridApi: GridApi) {
    gridApi.addGlobalListener(
      (type: "stateUpdated" | "rowDataUpdated" | "filterChanged" | "gridPreDestroyed" | string) => {
        switch (type) {
          case "stateUpdated":
            this.setTable(id, { gridState: gridApi.getState() });
            break;
          case "rowDataUpdated":
          case "filterChanged":
            this.setTableUi(id, { total: gridApi.getDisplayedRowCount() });
            break;
        }
      },
    );

    const subscription1 = this.quickFilter$(id).subscribe((quickFilter) => {
      gridApi.updateGridOptions({ quickFilterText: quickFilter });
    });
    const subscription2 = this.externalFilter$(id).subscribe(() => {
      gridApi.onFilterChanged();
    });
    const subscription3 = this.rangeFilter$(id).subscribe(() => {
      gridApi.onFilterChanged();
    });
    const subscription4 = this.reset$(id).subscribe((type) => {
      if (type === "FILTERS") {
        gridApi.setFilterModel({});
      } else if (type === "COLUMNS") {
        gridApi.resetColumnState();
        gridApi.forEachNode((node) => {
          node.setExpanded(false);
        });
      }
    });
    const subscription5 = this.export$(id).subscribe((type) => {
      const columnKeys = gridApi
        .getColumnState()
        .filter(({ hide }) => !hide)
        .filter(({ colId }) => colId != "_id" && colId != "rowGroup")
        .map(({ colId }) => colId);
      if (type === "CSV") {
        gridApi.exportDataAsCsv(this.getExportParams(id, type, columnKeys) as CsvExportParams);
      } else if (type === "EXCEL") {
        gridApi.exportDataAsExcel(this.getExportParams(id, type, columnKeys) as ExcelExportParams);
      }
    });
    this.subscriptions.push(subscription1, subscription2, subscription3, subscription4, subscription5);
  }

  initExternalFilter<T, U>(
    id: TableName,
    gridApi: GridApi,
    callback: (node: RowNode<T>, externalFilter: U, rangeFilter?: string[]) => boolean,
  ) {
    gridApi.updateGridOptions({ isExternalFilterPresent: () => true });
    gridApi.updateGridOptions({
      doesExternalFilterPass: (node: RowNode<T>) => {
        return callback(node, store.query(getEntity(id))?.externalFilter as U, store.query(getEntity(id))?.rangeFilter);
      },
    });
  }

  destroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  getExportParams(id: TableName, type: "EXCEL" | "CSV", columnKeys: string[]): CsvExportParams | ExcelExportParams {
    return {
      columnKeys,
      fileName: id,
      ...(type === "EXCEL" && {
        sheetName: id,
        exportAsExcelTable: true,
      }),
      skipPinnedTop: true,
      skipPinnedBottom: true,
      processCellCallback: (params) => {
        if (!params.value) return "";
        if (params.column.getColDef().type === "date") {
          return formatDate(params.value);
        }
        return params.value;
      },
    };
  }

  reset(id: TableName, type: "FILTERS" | "COLUMNS") {
    this.setTable(id, { gridState: null, quickFilter: "", reset: type });
    setTimeout(() => {
      this.setTable(id, { reset: null });
    }, 100);
  }

  export(id: TableName, type: "EXCEL" | "CSV") {
    this.setTable(id, { export: type });
    setTimeout(() => {
      this.setTable(id, { export: null });
    }, 100);
  }
}
