import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { IUser } from "app/_shared/types/models/user";

@Component({
  standalone: false,
  selector: "ag-grid-custom-cell-avatar",
  template: `
    <div class="h-full flex items-center">
      <div class="mr-2">
        <app-user-avatar [user]="user" [size]="16" />
      </div>
      @if (fullName) {
        {{ fullName }}
      }
    </div>
  `,
})
export class AgGridCustomCellAvatarComponent implements ICellRendererAngularComp {
  public user: IUser;

  fullName: string;

  ngOnInit(): void {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams & { fullName: string }): void {
    this.user = this.getValueToDisplay(params);
    this.fullName = params.fullName;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.user = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
