import { Component, Input, OnInit } from "@angular/core";
import { ILog } from "app/_shared/types/models/log";

@Component({
  standalone: false,
  selector: "app-log-drawer-entity",
  templateUrl: "./log-drawer-entity.component.html",
})
export class LogDrawerEntityComponent implements OnInit {
  @Input() log: ILog;
  @Input() isCollapse: boolean = false;

  icon: string = "question-circle";
  name: string = "";

  ngOnInit(): void {
    this.name = this.log.model;
    switch (this.log.model) {
      case "User":
      case "Role":
        this.icon = "usergroup-delete";
        break;
      case "CompanyType":
      case "ReportingMarket":
      case "Region":
      case "Address":
      case "DocumentCategory":
      case "Currency":
      case "EmailCC":
      case "FileTemplate":
        this.icon = "setting";
        break;
      case "City":
        this.icon = "setting";
        this.name = "Port";
        break;
      case "Document":
        this.icon = "file";
        break;
      case "Company":
      case "Contact":
        this.icon = "bank";
        break;
      case "Product":
      case "ProductFamily":
      case "ProductPacking":
      case "ProductPrice":
      case "ProductCogs":
        this.icon = "shop";
        break;
      case "Batch":
        this.icon = "barcode";
        break;
      case "InventoryItem":
        this.icon = "group";
        this.name = "Warehouse transaction";
        break;
      case "PaymentTemplate":
      case "InTransitTimeTemplate":
      case "MilestoneTemplate":
        this.icon = "file-done";
        break;
      case "Order":
      case "OrderItem":
      case "OrderItemBatch":
      case "Milestone":
      case "DocumentSign":
      case "OrderContractPurchase":
      case "OrderContractTransformation":
      case "OrderContractRepackagingRelabeling":
      case "OrderContractLoss":
      case "OrderContractFreight":
      case "OrderContractSale":
      case "OrderContractCommission":
      case "OrderContractPostDelivery":
        this.icon = "project";
        break;
      case "Email":
        this.icon = "mail";
        break;
    }
  }
}
