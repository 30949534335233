import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { AgGridModule } from "ag-grid-angular";
import { provideGlobalGridOptions, ModuleRegistry, AllCommunityModule, AllEnterpriseModule } from "ag-grid-enterprise";
import {
  rowClassRulesMap,
  columnTypesMap,
  componentMap,
  excelStyles,
  sideBar,
  AG_THEME,
} from "app/_shared/utils/ag-grid.utils";

ModuleRegistry.registerModules([AllCommunityModule, AllEnterpriseModule]);

provideGlobalGridOptions({
  theme: AG_THEME,
  sideBar,

  columnMenu: "new",
  rowNumbers: { minWidth: 40, width: 40 },

  cacheQuickFilter: true,
  suppressDragLeaveHidesColumns: true,
  includeHiddenColumnsInQuickFilter: true,
  suppressContextMenu: true,

  rowClassRules: rowClassRulesMap,
  columnTypes: columnTypesMap,
  components: componentMap,
  getRowId: (params) => params.data?._id,
  excelStyles,

  defaultColDef: {
    flex: 1,
    minWidth: 150,
  },
});

@NgModule({
  imports: [CommonModule, AgGridModule],
  declarations: [],
  exports: [AgGridModule],
})
export class AgGridSetupModule {}
