import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { UserService } from "app/_shared/services/user.service";
import { LogFilter, Model } from "app/_shared/types/models/log";
import { IUser } from "app/_shared/types/models/user";
import { isEqual } from "lodash";
import { debounceTime, distinctUntilChanged, Observable } from "rxjs";

@Component({
  standalone: false,
  selector: "app-log-form",
  templateUrl: "./log-form.component.html",
  styles: [],
})
export class LogFormComponent implements OnInit {
  @Output() onChange = new EventEmitter<LogFilter>();

  filterForm = this.fb.group({
    query: this.fb.control<string>("", []),
    model: this.fb.control<string>(null, []),
    user: this.fb.control<string>(null, []),
  });

  models: Model[] = [
    "Role",
    "User",
    "Address",
    "City",
    "CompanyType",
    "ReportingMarket",
    "Region",
    "DocumentCategory",
    "Currency",
    "EmailCC",
    "Document",
    "Company",
    "Contact",
    "Product",
    "ProductFamily",
    "ProductPacking",
    "ProductFormulation",
    "ProductPrice",
    "Batch",
    "InventoryItem",
    "PaymentTemplate",
    "InTransitTimeTemplate",
    "MilestoneTemplate",
    "Order",
    "OrderItem",
    "OrderItemBatch",
    "Milestone",
    "DocumentSign",
    "Email",
    "FileTemplate",
  ];

  users$: Observable<IUser[]>;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
  ) {
    this.users$ = this.userService.getAll();
  }

  ngOnInit(): void {
    this.filterForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged(isEqual)).subscribe((value) => {
      this.onChange.emit(value as LogFilter);
    });
  }
}
