import { IAddress } from "app/_shared/types/models/address";
import { IBatch } from "app/_shared/types/models/batch";
import { ICompany } from "app/_shared/types/models/company";
import { IProduct, IProductFamily } from "app/_shared/types/models/product";
import { ICurrency } from "app/_shared/types/models/setting";
import { ETransportType, ICity, IPaymentTemplate } from "app/_shared/types/models/template";
import { IUser } from "app/_shared/types/models/user";
import { ECostType } from "app/_shared/types/order-input";

export const incoterms = [
  "EXW",
  "CFR",
  "CIF",
  "CPT",
  "CIP",
  "DAP",
  "DAT",
  "DDP",
  "FCA",
  "FAS",
  "FOB",
  "FOB + INSURANCE",
];

export enum EOrderType {
  TRADING = "TRADING",
  COMMISSIONED = "COMMISSIONED",
  IN = "IN",
  OUT = "OUT",
  MOVE = "MOVE",
}

export enum EOrderSubType {
  PRODUCTION = "PRODUCTION",
  REPACKAGING_RELABELING = "REPACKAGING_RELABELING",
  WAREHOUSE_TRANSFER = "WAREHOUSE_TRANSFER",
  LOSSES = "LOSSES",
  RETURN = "RETURN",
  EXPIRATION_EXTENSION = "EXPIRATION_EXTENSION",
}

export enum EOrderHop {
  NO_HOP = "NO HOP",
  ONE_HOP = "ONE HOP",
  TWO_HOPS = "TWO HOPS",
  THREE_HOPS = "THREE HOPS",
}

export enum EOrderStatus {
  ORDER_INCOMPLETE = "Order Incomplete",
  ORDER_CANCELLED = "Order Cancelled",
  ORDER_ON_HOLD = "Order On Hold",
  ORDER_LATE_APPROVAL = "Order Late Approval",
  ORDER_UNDER_APPROVAL = "Order Under Approval",
  ORDER_PAYMENT_DUE = "Order Payment Due",
  ORDER_UNDER_PRODUCTION = "Order Under Production",
  ORDER_LATE_DISPATCH = "Order Late Dispatch",
  ORDER_DISPATCHED = "Order Dispatched",
  ORDER_LATE_TRANSIT = "Order Late Transit",
  ORDER_IN_TRANSIT = "Order In Transit",
  ORDER_LATE_DELIVERY = "Order Late Delivery",
  ORDER_DELIVERED = "Order Delivered",
  ORDER_COMPLETED = "Order Completed",
}

export interface IUnitCost {
  displayName: string;
  value: number;
  currency: ICurrency;
}

export interface IOrderTargetComment {
  comment: string;
  date: Date;
  user: IUser;
}

export interface IOrder {
  _id: string;
  displayName: string;
  reference: string;
  externalReference: string;
  comment: string;
  type: EOrderType;
  subType: EOrderSubType;
  hop: EOrderHop;
  status: EOrderStatus;
  date: string;
  eta: string;
  targetDate: string;
  targetDateIgnore: boolean;
  targetDateComments: IOrderTargetComment[];
  lastUpdatedAt: string;
  validityDate: string;
  assignee: IUser;
  source: ICompany;
  destination: ICompany;
  transporter: ICompany;
  order: IOrder;
  orderDate: IOrderDate;
  relatedOrders: IOrder[];

  invoiceText: string;
  containerNumber: string;
  billingNumber: string;
  trackingNumber: string;
  invoiceNumber: string;

  isCancelled: boolean;
  isOnHold: boolean;
  isConfirmed: boolean;
  isShippedOnBoard: boolean;
  isInTransit: boolean;
  isDelivered: boolean;
  isInStock: Boolean;
  isOldOrder: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum EOrderItemOperation {
  REPACK = "REPACK",
  TRANSFER = "TRANSFER",
  PRODUCTION = "PRODUCTION",
}

export enum EOrderItemOperationSourceType {
  AVAILABLE = "AVAILABLE",
  IN_ORDER = "IN_ORDER",
  WAREHOUSE_TRANSFER = "WAREHOUSE_TRANSFER",
  TOLL_MANUFACTURER = "TOLL_MANUFACTURER",
}

export interface IOrderItemOperation {
  type: EOrderItemOperation;
  orderItem: IOrderItem;
  source?: EOrderItemOperationSourceType;
  relatedOrder?: IOrder;
  relatedOrderItem?: IOrderItem;
}

export interface IOrderItem {
  _id: string;
  displayName: string;
  quantity: number;
  sort: number;
  order: IOrder;
  product: IProduct;
  unitCost: IUnitCost;
  operation?: IOrderItemOperation;
  createdAt: string;
  updatedAt: string;
}

export interface IOrderItemBatch {
  _id: string;
  displayName: string;
  quantity: number;
  unitCost: IUnitCost;
  isUneditable: boolean;
  isUndeletable: boolean;
  operation: EOrderItemOperation;
  order: IOrder;
  orderItem: IOrderItem;
  product: IProduct;
  batch: IBatch;
  createdAt: string;
  updatedAt: string;
}

export enum EOrderContract {
  PURCHASE = "PURCHASE",
  TRANSFORMATION = "TRANSFORMATION",
  REPACKAGING_RELABELING = "REPACKAGING_RELABELING",
  LOSS = "LOSS",
  FREIGHT_ONE = "FREIGHT_ONE",
  FREIGHT_TWO = "FREIGHT_TWO",
  FREIGHT_THREE = "FREIGHT_THREE",
  COMMISSION = "COMMISSION",
  SALE = "SALE",
  POST_DELIVERY = "POST_DELIVERY",
}

export enum EOrderContractType {
  PURCHASE = "PURCHASE",
  TRANSFORMATION = "TRANSFORMATION",
  REPACKAGING_RELABELING = "REPACKAGING_RELABELING",
  LOSS = "LOSS",
  FREIGHT = "FREIGHT",
  COMMISSION = "COMMISSION",
  SALE = "SALE",
  POST_DELIVERY = "POST_DELIVERY",
}

export interface ICost {
  _id: string;
  displayName: string;
  type: ECostType;
  value: number;
  isUndeletable: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IOrderContract {
  _id: string;
  contract: EOrderContractType;
  order: IOrder;
  displayName: string;
  costs: ICost[];
  paymentTemplate: IPaymentTemplate;
  paymentCurrency: ICurrency;
  paymentExchangeRate: number;
  position: 1 | 2 | 3;
  createdAt: string;
  updatedAt: string;
}

export interface IOrderContractPurchase extends IOrderContract {
  courier: ICompany;
  originatingAddress: IAddress;
  deliveryAddress: IAddress;
  incoterm: string;
  discountType: "percentage" | "absolute value";
  discount: number;
}

export interface IOrderContractTransformation extends IOrderContract {
  client: ICompany;
  tollManufacturer: ICompany;
  vatDescription: string;
  vatType: "percentage" | "absolute value";
  vat: number;
}

export interface IOrderContractRepackagingRelabeling extends IOrderContract {
  coPacker: ICompany;
  comment: string;
}

export interface IOrderContractLoss extends IOrderContract {
  disposalResponsible: ICompany;
  type: string;
  description: string;
}

export interface IOrderContractFreight extends IOrderContract {
  freightForwarder: ICompany;
  transporter: ICompany;
  transportType: ETransportType;
  consignee: ICompany;
  fiscalRepresentative: ICompany;
  pickupAddress: IAddress;
  sourcePort: ICity;
  deliveryAddress: IAddress;
  destinationPort: ICity;
  incoterm: string;
  contractor: ICompany;
  isFirst: boolean;
  isLast: boolean;
}

export interface IOrderContractSale extends IOrderContract {
  courier: ICompany;
  deliveryAddress: IAddress;
  incoterm: string;
  destinationPort: string;
  paymentTerms: string;
  discountType: "percentage" | "absolute value";
  discount: number;
  agent: ICompany;
  commissionType: "percentage" | "absolute value" | "unit price";
  commission: number;
  vatDescription: string;
  vatType: "percentage" | "absolute value";
  vat: number;
}

export interface IOrderContractCommission extends IOrderContract {
  courier: ICompany;
  deliveryAddress: IAddress;
  incoterm: string;
  discountType: "percentage" | "absolute value";
  discount: number;
  vatDescription: string;
  vatType: "percentage" | "absolute value";
  vat: number;
}

export interface IOrderContractPostDelivery extends IOrderContract {
  supplier: ICompany;
  comment: string;
}

export interface IOrderItem {
  _id: string;
  displayName: string;
  quantity: number;
  sort: number;
  order: IOrder;
  product: IProduct;
  createdAt: string;
  updatedAt: string;
}

export interface IOrderLine {
  _id: string;

  displayName: string;
  quantity: number;

  price: number;
  discountType: "percentage" | "absolute value";
  discount: number;
  orderDiscountType: "percentage" | "absolute value";
  orderDiscount: number;
  commissionType: "percentage" | "absolute value" | "unit price";
  commission: number;

  costs: number;
  insuranceCosts: number;
  handlingCosts: number;
  extraCosts: number;
  courierCosts: number;
  shippingCosts: number;
  blCosts: number;
  customDutyCosts: number;
  productionCosts: number;
  repackagingCosts: number;
  disposalCosts: number;
  othersCosts: number;

  exchangeRate: number;
  total: number;
  baseTotal: number;
  lineTotal: number;

  order: IOrder;
  orderItem: IOrderItem;
  orderContract: IOrderContract;
  product: IProduct;
  currency: ICurrency;

  createdAt: string;
  updatedAt: string;
}

export interface IOrderDate {
  _id: string;

  period: string;
  quarter: "Q1" | "Q2" | "Q3" | "Q4";
  date: string;
  targetDate: string;
  releaseDate: string; //textract

  requestFromClientExpectedDate: string;
  requestFromClientDate: string;

  proformaInvoiceToClientExpectedDate: string;
  proformaInvoiceToClientDate: string;

  purchaseOrderFromClientExpectedDate: string;
  purchaseOrderFromClientDate: string;

  purchaseOrderToSupplierExpectedDate: string;
  purchaseOrderToSupplierDate: string;

  dispatchFromFactoryExpectedDate: string;
  dispatchFromFactoryDate: string;

  shippedOnBoardExpectedDate: string;
  shippedOnBoardDate: string;

  shippingExpectedDate: string; //bl/awb expected date
  shippingDate: string; //bl/awb date

  deliveryExpectedDate: string; //eta
  deliveryDate: string;

  inStockExpectedDate: string;
  inStockDate: string;

  invoiceDate: string;
  invoiceExpectedDate: string;

  invoiceFromSupplierDate: string;
  invoiceFromSupplierExpectedDate: string;

  order: IOrder;

  createdAt: string;
  updatedAt: string;
}

export interface IOrderStatCost {
  costs: number;
  insuranceCosts: number;
  shippingCosts: number;
  courierCosts: number;
  restCosts: number;
  handlingCosts: number;
  extraCosts: number;
  blCosts: number;
  customDutyCosts: number;
  productionCosts: number;
  repackagingCosts: number;
  disposalCosts: number;
  othersCosts: number;
}

export interface IOrderStatCosts {
  purchaseCosts: IOrderStatCost;
  transformationCosts: IOrderStatCost;
  repackCosts: IOrderStatCost;
  lossCosts: IOrderStatCost;
  shippingCosts: IOrderStatCost;
  saleCosts: IOrderStatCost;
  commissionCosts: IOrderStatCost;
  postDeliveryCosts: IOrderStatCost;
}

export interface IOrderStat {
  _id: string;

  date: Date;
  type: EOrderType;
  subType: EOrderSubType;

  order: IOrder;
  orderDate: IOrderDate;
  orderContract: IOrderContractSale | IOrderContractCommission;
  assignee: IUser;
  source: ICompany;
  destination: ICompany;
  transporter: ICompany;
  destinationPort: ICity;

  purchasePrice: number;
  purchaseDiscount: number;
  purchaseTotalPrice: number;
  purchaseCosts: IOrderStatCost;
  purchaseAgentCosts: number;
  purchaseOrderDiscount: number;
  purchaseFinalPrice: number;
  purchaseUnitPrice: number;
  purchaseInputPrice: number;
  purchaseContractPrice: number;
  purchaseContractCurrency: ICurrency;

  shippingCosts: IOrderStatCost;
  shippingFinalPrice: number;
  shippingUnitPrice: number;

  salePrice: number;
  saleDiscount: number;
  saleTotalPrice: number;
  saleCosts: IOrderStatCost;
  saleOrderDiscount: number;
  saleFinalPrice: number;
  saleUnitPrice: number;
  saleInputPrice: number;
  saleContractPrice: number;
  saleContractCurrency: ICurrency;

  commissionPrice: number;
  commissionDiscount: number;
  commissionTotalPrice: number;
  commissionCosts: IOrderStatCost;
  commissionOrderDiscount: number;
  commissionFinalPrice: number;
  commissionUnitPrice: number;
  commissionInputPrice: number;
  commissionContractPrice: number;
  commissionContractCurrency: ICurrency;

  cost: number;
  unitCost: number;
  income: number;
  unitIncome: number;
  margin: number;
  unitMargin: number;
  grossMargin: number;

  isDeleted: true;

  createdAt: string;
  updatedAt: string;
}

export interface IOrderItemStat extends IOrderStat {
  orderItem: IOrderItem;
  product: IProduct;
  family: IProductFamily;
}

export interface IOrderItemBatchStat extends IOrderItemStat {
  orderItemBatch: Omit<IOrderItemBatch, "order" | "orderItem" | "product"> & {
    order: string;
    orderItem: string;
    product: string;
    batchStatus: "EXPECTED" | "AVAILABLE" | "DISPATCHED" | "N/A";
    shelfLife: number;
    remainingShelfLife: number;
    remainingShelfLifePercentage: number;
  };
}
