import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ standalone: false, name: "initials" })
export class InitialsPipe implements PipeTransform {
  transform(str: string): string {
    if (!str || typeof str !== "string") return "";
    return str.charAt(0);
    /*.split(" ")
      .map((s) => s.charAt(0).toUpperCase())
      .join("");*/
  }
}
