import { ICellRendererAngularComp } from "ag-grid-angular";
import { GroupCellRendererParams, ICellRendererParams } from "ag-grid-enterprise";
import { Component } from "@angular/core";

@Component({
  standalone: false,
  selector: "ag-grid-custom-cell-action",
  template: ` <span class="font-semibold">{{ params.value }}</span> `,
})
export class AgGridCustomInnerGroupActionComponent implements ICellRendererAngularComp {
  params: GroupCellRendererParams;

  constructor() {}

  ngOnInit(): void {}

  // gets called once before the renderer is used
  agInit(params: GroupCellRendererParams): void {
    this.params = params;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
