import { Component, OnInit } from "@angular/core";
import { SharepointSyncService } from "app/_shared/services/sharepoint-sync.service";
import { ESharepointReport, ISharepointReport, ISharepointSync } from "app/_shared/types/models/sharepoint-sync";
import { finalize } from "rxjs";

@Component({
  standalone: false,
  selector: "app-sharepoint-sync",
  templateUrl: "./sharepoint-sync.component.html",
  styles: [],
})
export class SharepointSyncComponent implements OnInit {
  spinning: boolean = true;

  sharepointSync: ISharepointSync;
  reports: ISharepointReport[] = Object.values(ESharepointReport).map((name) => ({
    name,
    isSynced: false,
  }));

  constructor(private sharepointSyncService: SharepointSyncService) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.sharepointSyncService
      .getOne()
      .pipe(finalize(() => (this.spinning = false)))
      .subscribe((sharepointSync) => {
        this.sharepointSync = sharepointSync;
        this.reports = sharepointSync?.reports;
      });
  }

  sync() {
    this.sharepointSyncService.create().subscribe((sharepointSync) => {
      this.sharepointSync = sharepointSync;
      this.reports = sharepointSync?.reports;
      this.load();
    });
  }
}
