import { Component, Input, OnInit } from "@angular/core";
import { AppUser, IUser } from "app/_shared/types/models/user";

@Component({
  standalone: false,
  selector: "app-user-avatar",
  template: `
    <nz-avatar
      nzShape="square"
      [nzSize]="size"
      [nzSrc]="user?.avatar?.url"
      [nzText]="user?.displayName | initials"
      [ngStyle]="{ 'background-color': user?.avatar?.url ? null : user?.avatar?.color }" />
  `,
})
export class UserAvatarComponent implements OnInit {
  @Input() user: IUser | AppUser;
  @Input() size: "large" | "small" | "default" | number = "default";

  constructor() {}

  ngOnInit(): void {}
}
