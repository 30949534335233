import { Component, OnInit } from "@angular/core";
import { AppStore } from "app/_shared/stores/app.store";
import { environment } from "environments/environment";
import { delay } from "rxjs";
import { Router } from "@angular/router";

@Component({
  standalone: false,
  selector: "app-full",
  templateUrl: "./full.component.html",
  styleUrls: [],
})
export class FullComponent implements OnInit {
  isCollapsed: boolean;
  hasError = false;
  status: number;
  loading: boolean = true;

  hasPermission = false;

  isDevelopment = environment.development;

  constructor(
    private router: Router,
    private appStore: AppStore,
  ) {}

  ngOnInit(): void {
    this.appStore.isLoading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.appStore.hasPermission$.subscribe((hasPermission) => {
      this.hasPermission = hasPermission;
    });

    this.appStore.isCollapsed$.pipe(delay(100)).subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
    this.appStore.error$.subscribe((res) => {
      this.hasError = res.hasError;
      this.status = res.status;
    });
  }

  reload(): void {
    window.location.reload();
  }

  async logout() {
    this.appStore.setState({ user: null, permissions: [] });
    await this.router.navigate(["login"]);
  }
}
