import { Injectable } from "@angular/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { ModalOptions } from "ng-zorro-antd/modal/modal-types";

@Injectable({
  providedIn: "root",
})
export class TmpDrawerService {
  constructor(private modalService: NzModalService) {}

  create<T, D = NzSafeAny, R = NzSafeAny>(config: ModalOptions<T, D, R>) {
    return this.modalService.create({
      ...config,
      nzFooter: null,
      nzClassName: "tmp-drawer",
    });
  }
}
