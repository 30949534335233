import { ICellEditorAngularComp } from "ag-grid-angular";
import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { ICellEditorParams } from "ag-grid-enterprise";
import { parseISO, isBefore, startOfDay } from "date-fns";
import { NzDatePickerComponent } from "ng-zorro-antd/date-picker";

interface Option {
  disablePastDate: boolean;
}

@Component({
  standalone: false,
  selector: "ag-grid-custom-edit-date",
  template: ` <nz-date-picker
    #datePicker
    autofocus
    class="w-full"
    nzFormat="dd/MM/yyyy"
    nzSize="small"
    nzBorderless
    [nzDisabledDate]="disablePastDate ? isPastDate : null"
    [nzShowToday]="false"
    [nzAllowClear]="false"
    [(ngModel)]="date"
    (ngModelChange)="onDateChange($event)" />`,
})
export class AgGridCustomEditDateComponent implements ICellEditorAngularComp, AfterViewInit {
  params: ICellEditorParams;

  date: Date;
  disablePastDate: boolean = true;

  @ViewChild("datePicker") datePicker!: NzDatePickerComponent;

  isPastDate = (current: Date): boolean => isBefore(current, startOfDay(new Date()));

  agInit(params: ICellEditorParams & Option): void {
    this.params = params;
    this.disablePastDate = params.disablePastDate != null ? params.disablePastDate : true;
    this.date = params.value ? parseISO(params.value) : null;
  }

  ngAfterViewInit(): void {
    // Use a slight delay to ensure the view is ready before opening the popup
    setTimeout(() => {
      if (this.datePicker) {
        this.datePicker.open(); // Open the calendar popup
      }
    });
  }

  getValue(): string | null {
    return this.date ? this.date.toISOString() : null;
  }

  onDateChange(value: Date): void {
    this.date = value;
    // Automatically stop editing when a new date is selected
    if (this.params && this.params.api) {
      this.params.api.stopEditing();
    }
  }
}
