import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  standalone: false,
  selector: "ag-grid-custom-cell-email",
  template: `
    @if (params?.data) {
      <div class="w-full flex items-center">
        <a
          [href]="'mailto:' + params.value"
          class="hover:underline"
          nz-tooltip
          [nzTooltipTitle]="'Send email to ' + params.data?.displayName"
          >{{ params.value }}</a
        >
      </div>
    } @else {
      {{ params.value }}
    }
  `,
})
export class AgGridCustomCellEmailComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
