import { Component, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AppStore } from "app/_shared/stores/app.store";
import { head } from "lodash";
import { switchMap, finalize, filter, map } from "rxjs";
import { scopes } from "app/_shared/utils/app.utils";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AppService } from "app/_shared/services/app.service";

@Component({
  standalone: false,
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msalService: MsalService,
    private appService: AppService,
    private appStore: AppStore,
  ) {
    this.getRouteData();
  }

  ngOnInit(): void {
    const user = this.appStore.user();

    if (!user || (user as any)?.token) {
      this.appStore.setState({ user: null });
      this.router.navigate(["/login"]);
    }

    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      const nuxappAccount = accounts.find((account) => account.username === user.email);
      this.msalService.instance.setActiveAccount(nuxappAccount ?? head(accounts));
    }

    this.msalService
      .initialize()
      .pipe(
        switchMap(() => this.msalService.acquireTokenSilent({ scopes })),
        finalize(() => {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        }),
      )
      .subscribe({
        next: () => {
          this.appService.init();
        },
        error: () => {
          this.router.navigate(["/login"]);
        },
      });
  }

  getRouteData() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((rt) => {
          while (rt.firstChild) {
            rt = rt.firstChild;
          }
          return rt;
        }),
        map((rt) => rt.data),
      )
      .subscribe((data) => {
        this.appStore.setState({ routeData: data });
      });
  }
}
