import { LicenseManager } from "ag-grid-enterprise";
import { ApplicationRef } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableElfProdMode } from "@ngneat/elf";
import { devTools } from "@ngneat/elf-devtools";

import { AppModule } from "app/app.module";

import { environment } from "environments/environment";

if (environment.production) {
  enableElfProdMode();
}

LicenseManager.setLicenseKey(environment.agGridLicense);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    devTools({
      postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
    });
  })
  .catch((err) => console.error(err));
