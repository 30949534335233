import { Component, OnInit } from "@angular/core";
import { LogService } from "app/_shared/services/log.service";
import { ILog, LogFilter } from "app/_shared/types/models/log";
import { finalize } from "rxjs";

@Component({
  standalone: false,
  selector: "app-log-drawer",
  templateUrl: "./log-drawer.component.html",
})
export class LogDrawerComponent implements OnInit {
  loading: boolean = true;
  loadingMore: boolean;

  cursor: number = 0;

  empty = Array(15).fill(null);
  logs: ILog[] = [];

  constructor(private logService: LogService) {}

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.loadingMore = true;
    this.logService
      .search(this.cursor)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.loadingMore = false;
        }),
      )
      .subscribe((data) => {
        if (this.cursor === 0) {
          this.logs = data;
        } else {
          this.logs = [...this.logs, ...data];
        }
        this.cursor++;
      });
  }

  onFilterChanged(filter: LogFilter) {
    this.loading = true;
    this.cursor = 0;
    this.logService
      .search(this.cursor, filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((data) => {
        this.logs = data;
        this.cursor++;
      });
  }
}
